<template>
  <div id="f-sidebar">
    <button
      v-show="!state.sidebar"
      id="f-corner"
      v-b-toggle.f-b-sidebar
      title="Afficher le menu Framasoft"
      @click="searchAll()"
    >
      <div class="logo-menu">
        <span class="text-uppercase">Menu</span>
        <i class="fac fa-framasoft-alt fa-white mt-3 ml-1 fa-3x"></i>
      </div>
    </button>
    <b-sidebar
      id="f-b-sidebar"
      aria-labelledby="f-brand"
      backdrop
      :class="sidebarClass"
      header-class="position-sticky sticky-top p-0 fb-g4"
      no-header-close
      right
      shadow
      text-variant=" "
      tag="aside"
      :visible="state.sidebar"
      @shown="sidebarVisibility(true)"
      @hidden="sidebarVisibility(false)"
    >
      <template v-slot:title>
        <!-- Main Navigation header -->
        <div
          v-show="!/^help/.test(view)"
          class="m-2"
        >
          <b-button-close
            v-b-toggle.f-b-sidebar
            class="fixed-top py-1 px-2"
            :aria-label="$t('txt.close')"
            style="left: auto"
          />
          <h1 class="mx-2 mt-3 h3 d-flex justify-content-center align-items-center">
            <a
              id="f-brand"
              class="d-flex align-items-center"
              :href="$t('link.soft')"
              style="font-size: 2.25rem"
            >
              <img
                alt=""
                class="d-inline"
                :src="`${$t('baseurl')}img/logo-no-padding.svg`"
              />
              <span v-html="$t('color.soft')"></span>
            </a>
          </h1>
          <b-row class="m-0">
            <b-button
              id="btn-about"
              variant="light btn-sm mx-1"
              :title="$t('about.title', {
                name: $te(`txt.${$t('site')}`)
                  ? $t(`txt.${$t('site')}`)
                  : $t('txt.soft')
              })"
              @click="modal.about = true;"
            >
              <i class="fas fa-question fa-fw fc-g6"></i>
              <span
                class="sr-only"
                v-html="$t('about.btn')"
              ></span>
            </b-button>

            <!-- Support button -->
            <b-button
              id="btn-soutenir"
              :href="`${$t('link.soutenir')}/?f=nav`"
              rel="noopener"
              variant="warning btn-sm ml-auto"
              target="_blank"
              :title="$t('fnav.soutenir.t1', '-t')"
            >
              <i :class="`${$t('icon.soutenir')} fa-lg fa-fw fa-white`"></i>
              <span v-html="$t('fnav.soutenir.name', '-t')"></span>
            </b-button>
          </b-row>
          <b-row class="mx-0 mt-2">
            <b-input-group size="sm">
              <b-input-group-prepend>
                <b-button-group
                  class="mr-2 ml-1"
                  size="sm"
                >
                  <b-button
                    :variant="`light border-0 ${view === 'grid' ? 'active' : ''}`"
                    @click="view = 'grid'; search = ''"
                  >
                    <i class="fas fa-grip-vertical fa-fw fc-g6"></i>
                    <span
                      class="sr-only"
                      v-html="$t('fnav.view.grid')"
                    ></span>
                  </b-button>
                  <b-button
                    :variant="`light border-0 ${view === 'category' ? 'active' : ''}`"
                    @click="view = 'category'; search = ''"
                  >
                    <i class="fas fa-bars fa-fw fc-g6"></i>
                    <span
                      class="sr-only"
                      v-html="$t('fnav.view.category')"
                    ></span>
                  </b-button>
                </b-button-group>
                <label
                  for="fsb-search-input"
                  class="sr-only"
                  v-html="$t('txt.search')"
                ></label>
              </b-input-group-prepend>
              <b-input
                id="fsb-search-input"
                v-model="search"
                :placeholder="$t('txt.search')"
                type="text"
                class="border-0 px-2 py-1 fb-g2"
                @focusin="state.helpMenu = false; info = ''"
                @focusout="state.helpMenu = true"
              />
              <b-input-group-append>
                <b-button
                  v-show="search === ''"
                  aria-hidden="true"
                  variant="light"
                >
                  <i class="fas fa-magnifying-glass fc-g7"></i>
                </b-button>
                <b-button
                  v-show="search !== ''"
                  variant="light"
                  @click="search = ''"
                >
                  <i class="fas fa-delete-left fc-g7"></i>
                  <span
                    class="sr-only"
                    v-html="$t('txt.clear')"
                  ></span>
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-row>
        </div>
        <!-- Help navigation header -->
        <b-row
          v-show="/^help/.test(view)"
          class="fb-o7 fc-light mx-0"
        >
          <div class="col-2 h5 my-0 pl-0">
            <b-button
              variant="link"
              @click="view = 'category'"
            >
              <i class="fas fa-chevron-left fa-white"></i>
              <span
                class="sr-only"
                v-html="$t('txt.back')"
              ></span>
            </b-button>
          </div>
          <h2 class="col-8 h5 text-white text-center mt-2 px-0">
            <b v-html="$t('fnav.sites.aide.name')"></b>
          </h2>
          <div class="col-2 h5 text-white text-right my-0 pr-3">
            <b-button
              v-b-toggle.f-b-sidebar
              variant="link"
            >
              <i class="fas fa-xmark fa-white"></i>
              <span
                class="sr-only"
                v-html="$t('txt.close')"
              ></span>
            </b-button>
          </div>
        </b-row>
      </template>

      <template v-slot:default>
        <!-- Main navigation -->
        <div
          v-show="!/^help/.test(view)"
          class="p-2"
        >
          <!-- Default view -->
          <Categories
            v-show="view === 'category' && search === ''"
            :categories="categories"
            @collapseChange="updateSidebarClass()"
          />

          <Grid
            v-show="view === 'grid' && search === ''"
            :pages="pages"
          />

          <Info
            v-if="info"
            v-show="view === 'info' && info !== ''"
            :site="info"
            @goBack="view = 'category'; info = ''"
          />

          <Search
            v-show="search !== ''"
            :search="search"
            :categories="categories"
          />
        </div>
        <!-- Help FAQ & Feedback -->
        <div
          v-if="$t('site') !== 'contact'"
          v-show="/^help/.test(view)"
          id="f-fb-menu"
          class="p-3 fb-o2"
        >
          <a
            id="f-fb-top"
            ref="f-fb-top"
          ></a>

          <FAQ
            v-show="view === 'help s-faq'"
            :open="view === 'help s-faq'"
            :search="search"
            :status="status"
            @scrolltop="$refs['f-fb-top'].scrollIntoView()"
            @goToContact="view = 'help s-contact'"
          />

          <Participate v-show="view === 'help s-feedback'" />

          <ContactForm
            v-show="/contact/.test(view)"
            section="contact faq"
          />
        </div>
      </template>
      <template v-slot:footer>
        <b-row
          v-if="$t('site') !== 'contact'"
          v-show="!/^help/.test(view) && search === '' && state.helpMenu && info ===''"
          class="m-0"
        >
          <div
            id="fsb-feedback"
            class="fb-g4 px-3 pt-1 pb-3 w-100"
          >
            <h2
              class="h5 text-center mt-1 px-0"
              v-html="$t('fnav.sites.aide.name')"
            ></h2>
            <!-- Service documentation opened in a modal -->
            <a
              :href="$t('link.docs') + (doc ? `/${$t(`doc.${$t('site')}[0]`)}/index.html` : '')"
              class="btn btn-default btn-block px-3 d-flex align-items-center"
              onclick="return false;"
              @click.prevent.stop="modal.docs = true;"
            >
              <i
                :class="`${$t('icon.doc')} fa-fw fa-lg fc-o6 fa-shape-circle fa-white mr-3 mt-0`"
              ></i>
              <span v-html="$t('feedback.menu.docs')"></span>
            </a>
            <!-- Faq of the service, then contact form -->
            <a
              :href="`${$t('link.contact')}/faq/#${$t('lname')}`"
              class="btn btn-default btn-block px-3 d-flex align-items-center"
              onclick="return false;"
              @click.prevent.stop="view = 'help s-faq'; initContactForm()"
            >
              <Status v-if="state.sidebar" />

              <i class="fas fa-question fa-fw fa-lg fc-g6 fa-shape-circle fa-white mr-3 mt-0"></i>
              <span v-html="$t('feedback.menu.faq')"></span>
            </a>
            <!-- Git of the service -->
            <a
              href="#feedback"
              class="btn btn-default btn-block px-3 d-flex align-items-center"
              onclick="return false;"
              @click.prevent.stop="view = 'help s-feedback'"
            >
              <i class="fas fa-paw fa-fw fa-lg fc-f7 fa-shape-circle fa-white mr-3 mt-0"></i>
              <span v-html="$t('feedback.menu.participate')"></span>
            </a>
          </div>
        </b-row>
        <b-row
          v-show="/^info/.test(view) && info !== ''"
          align-v="center"
          class="m-0 pt-2 pb-3 fb-g4 text-uppercase"
        >
          <b-col>
            <a
              v-if="$te(`doc.${info}`)"
              class="btn p-0"
              :href="`${$t('link.docs')}/${$t(`doc.${info}[0]`)}`"
              :title="$t('txt.docs')"
            >
              <i :class="`${$t('icon.doc')} fa-fw fc-o6 fa-shape-circle fa-white mt-0`"></i>
              <span
                class="sr-only"
                v-html="$t('txt.docs')"
              ></span>
            </a>
          </b-col>
          <b-col>
            <b-button
              v-if="info"
              block
              :href="!$te(`fnav.sites.${info}.link`)
                ? $t(`link.${info}`)
                : $t(`fnav.sites.${info}.link`)"
              rel="noopener"
              target="_blank"
              variant="primary btn-sm"
            >
              <span v-html="$t('txt.go')"></span>
              <i class="fas fa-chevron-right mt-n1"></i>
              <span
                class="sr-only"
                v-html="`(${$t('txt.newWindow')})`"
              ></span>
            </b-button>
          </b-col>
        </b-row>
        <b-row
          v-if="$t('site') !== 'contact'"
          v-show="/^help/.test(view)"
          class="m-0"
        >
          <!-- FAQ Search -->
          <div
            v-show="view === 'help s-faq'"
            class="search-form"
          >
            <label
              for="fsb-help-search"
              class="sr-only"
              v-html="$t('txt.search')"
            ></label>
            <b-input-group size="lg">
              <b-form-input
                id="fsb-help-search"
                v-model="search"
                :placeholder="$t('txt.search')"
                type="text"
                size="40"
              />
              <b-input-group-append
                class="border-top fb-light"
              >
                <b-button
                  v-show="search === ''"
                  aria-hidden="true"
                  variant="light fb-light"
                >
                  <i class="fas fa-magnifying-glass fc-g7"></i>
                </b-button>
                <b-button
                  v-show="search !== ''"
                  variant="light fb-light"
                  @click="search = ''"
                >
                  <i class="fas fa-delete-left fc-g7"></i>
                  <span
                    class="sr-only"
                    v-html="$t('txt.close')"
                  ></span>
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </div>
        </b-row>
      </template>
    </b-sidebar>

    <ModalDocs
      :open="modal.docs"
      @close="modal.docs = false"
    />

    <ModalAbout
      :open="modal.about"
      @close="modal.about = false"
    />
  </div>
</template>

<script>
// Main
import Categories from './Categories.vue';
import Grid from './Grid.vue';
import Info from './Info.vue';
import ModalAbout from '../modal/About.vue';
import ModalDocs from '../modal/Docs.vue';
import Search from './Search.vue';
import Status from '../feedback/Status.vue';
// Help
import ContactForm from '../feedback/ContactForm.vue';
import FAQ from '../feedback/FAQ.vue';
import Participate from '../feedback/Participate.vue';

export default {
  components: {
    Categories,
    Grid,
    Info,
    ModalAbout,
    ModalDocs,
    Search,
    Status,
    ContactForm,
    FAQ,
    Participate,
  },

  data() {
    // Prepare categories
    const grid = Object.keys(this.$t('taxonomy')).sort();
    const categories = {};
    const pages = [[]];
    let i = 0;
    const sites = {};
    grid.forEach((k) => {
      if (categories[this.$t(`taxonomy.${k}[0]`)] === undefined) {
        categories[this.$t(`taxonomy.${k}[0]`)] = [];
      }
      categories[this.$t(`taxonomy.${k}[0]`)].push(k);

      if (pages[i].length > 0
        && pages[i][pages[i].length - 1][0] !== k[0]) {
        i += 1;
        pages[i] = [];
      }
      pages[i].push(k);
    });
    categories['c-follow'] = ['newsletter', 'rss', 'mastodon', 'fpeertube',
      'fmobilizon', 'bluesky', 'linkedin', 'facebook', 'wikipedia'];

    return {
      categories,
      collapse: ['', ''],
      doc: this.$te(`doc.${this.$t('site')}`),
      info: '',
      modal: {
        about: false,
        docs: false,
      },
      pages,
      search: '',
      sidebarClass: '',
      sites,
      state: {
        helpMenu: true,
        sidebar: false,
      },
      status: [],
      view: 'category', // category, grid, help…
    };
  },

  watch: {
    collapse() {
      this.updateSidebarClass();
    },
    search() {
      this.view = this.view.replace('info', 'category');
      this.updateSidebarClass();
    },
    view() {
      this.updateSidebarClass();
    },
  },

  mounted() {
    // Keyboard shortcut to open sidebar Ctrl + Shift + F
    const kkeys = [];
    const shortcut = ['Control', 'F', 'Shift'];
    window.addEventListener('keydown', (e) => {
      if (shortcut.includes(e.key)) {
        kkeys.push(e.key);
        kkeys.sort();
        if (kkeys.length === 3 && kkeys.join() === shortcut.join()) {
          this.toggleFramaSidebar();
        }
      } else {
        kkeys.splice(0, kkeys.length);
      }

      if (kkeys.length >= 3) {
        kkeys.splice(0, kkeys.length);
      }
    }, true);

    this.updateSidebarClass();

    setInterval(() => {
      this.state.sidebar = (document.getElementsByTagName('html')[0]
        .getAttribute('data-sidebar') || false) === 'true';
    }, 200);

    this.$on('goToInfo', (e) => {
      this.goToInfo(e);
    });
  },

  methods: {
    initContactForm() {
      const contactForm = this.$children[0] // bs-Form
        .$children.find(child => child.$options._componentTag === 'ContactForm'); // eslint-disable-line no-underscore-dangle
      contactForm.submit = false;
      contactForm.success.status = '';
    },

    goToInfo(e) {
      this.view = 'info';
      this.info = e;
    },

    searchAll() {
      if (this.view === 'category' && this.search === '') {
        this.search = '  ';
      }
    },

    sidebarVisibility(visible) {
      document.getElementsByTagName('html')[0].setAttribute('data-sidebar', visible);
      this.state.sidebar = visible;
    },

    updateSidebarClass() {
      this.sidebarClass = `d-print-none s-${this.view} ${
        this.search !== '' ? 's-search' : ''
      } ${
        this.collapse[0] !== '' ? `c-lvl1 c-${this.collapse[0]}` : ''
      } ${
        this.collapse[1] !== '' ? `c-lvl2 c-${this.collapse[1]}` : ''
      }`;
    },
  },
};
</script>

<style lang="scss">
.fa-framasoft-alt {
  --icon: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.29 14.29"><path d="M.67.2C.527.204.453.331.56.43 5.51 2.78 4.78 5 5.42 7.55c.17.69 1.32 6.06 7.37 6.58.23.02.3-.25.06-.31-4.94-1.32-6.13-9.95-2.68-10.27.48-.04.63.16 1 .28.133.044.294.073.453.096l.002.002c.125.1.205.323.195.482-.48-.06-.67-.22-1.11-.45 0 0 .3.36.6.57.54.41 1.03.9 1.33 1.37.15.23.19.26.2.16a3.3 3.3 0 0 0-.52-1.87.526.526 0 0 1-.076-.227l.026-.002c.32-.6-.25-1.281-.73-1.781.52-.34 2.2-.71 2.4-.8.13-.05.07-.25-.04-.27-.77.05-2.6.2-3.43.52-1.45-.88-3.34-.2-4.3 1.3C5.19 1.5 3.81 1.079.672.2H.67zm12.334 9.66a.668.668 0 0 0-.668.667.668.668 0 0 0 .668.666.668.668 0 0 0 .668-.666.668.668 0 0 0-.668-.668zm-.416 1.962a.54.54 0 0 0-.54.54.54.54 0 0 0 .54.54.54.54 0 0 0 .539-.54.54.54 0 0 0-.54-.54z"/></svg>');
  --width: 1.000em;
  --square: 1.000em;
  &::before{ content: ''; }
}

#f-sidebar .btn .fa-shape-circle,
#f-contact-form .fa-shape-circle.fa-block-center {
  box-sizing: content-box !important;
}
</style>
